import { alpha, makeStyles } from '@material-ui/core/styles'

export const useFromStyles = makeStyles((theme:any) => ({
  editUserFormWrapper: {
    background: theme.palette.common.white
  },
  formWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    '&.formOnDialog': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    '& .MuiAlert-root': {
      width: '100%'
    }
  },
  FormItem: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('md')]: {
      width: '48%'
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        minHeight: 40,
        minWidth: '31%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        flex: '0 0 auto',
        paddingRight: theme.spacing(2)
      }
    },
    '&$FormRow .MuiInputLabel-root': {
      [theme.breakpoints.up('sm')]: {
        minWidth: '15%'
      }
    },
    '& .MuiFormControlLabel-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& .MuiOutlinedInput-root': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0
    },
    '& .MuiFormControl-root, & .MuiAutocomplete-root': {
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '&.text-left .MuiFormControl-root input':{
      direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
      textAlign: theme.direction === 'rtl' ? 'right' : 'left'
    },
    '& .MuiFormGroup-root[role="radiogroup"]': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(0),
        color:'#565656',
        '&$noMarTop':{
          marginTop: theme.spacing(0)
        },
      }
    },
    '& a': {
      display: 'block',
      width: '100%',
      textAlign: 'right'
    }
  },
  FormInner: {
    justifyContent: 'center',
    '& $FormItem': {
      [theme.breakpoints.up('md')]: {
        width: '67%'
      }
    }
  },
  FormItemSelect: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  FormVerticalCenter: {
    '& .MuiInputLabel-root': {
      [theme.breakpoints.up('sm')]: {
        minWidth: '0'
      }
    },
    '& p': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      minHeight: 40,
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center'
    }
  },
  FormRow: {
    width: '100%'
  },
  FormRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%'
  },
  FormColorInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    '& input[type="color"]': {
      width: 70,
      height: 40,
      marginRight: theme.spacing(1.5),
      padding: theme.spacing(0.125, 0.25)
    }
  },
  FormColorInputInner: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root': {
      direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
      display: 'inline-block',
      whiteSpace: 'nowrap'
    }
  },
  formCollapse:{
    '& .MuiCollapse-wrapper, & .MuiCollapse-wrapperInner':{
      display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%'
    }

  },
  PhoneInputWrapper: {
    direction: 'rtl',
    textAlign: 'right',
    flex: '1 1 auto',
    // width: '100%',
    '& .react-tel-input': {
      fontFamily: 'inherit',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& .react-tel-input .form-control': {
      width: '100%',
      paddingTop: theme.spacing(1.325),
      paddingBottom: theme.spacing(1.125),
      paddingRight: theme.spacing(5.625),
      '&[disabled]': {
        cursor: 'pointer'
        // backgroundColor: theme.palette.colors.body
      },
      '&:focus': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
      }
    },
    // '& $PhoneInputButton': {
    //   width: '100%',
    //   marginTop: theme.spacing(1),
    //   marginBottom: theme.spacing(0.5),
    //   '& .react-tel-input': {
    //     fontFamily: 'inherit',
    //     marginTop: theme.spacing(0),
    //     marginBottom: theme.spacing(0)
    //   }
    // },
    '& .country-list': {
      direction: 'ltr',
      textAlign: 'left',

      '& .search': {
        paddingLeft: theme.spacing(1.25)
      },
      '& .search-box': {
        marginLeft: theme.spacing(1)
      },
      '& .country-name': {
        marginRight: theme.spacing(1)
      },
      '& .dial-code': {
        direction: 'rtl',
        textAlign: 'right',
        display: 'inline-block'
      }
    }
  },
  FileUpload: {
    '& input': {
      display: 'none'
    }
  },
  actionButtonsWrapper: {
    padding: theme.spacing(0),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    '& button': {
      lineHeight: 1.3,
      padding: theme.spacing(1.25, 2.75),
      borderRadius: theme.spacing(1.25),
      [theme.breakpoints.up('sm')]: {
        minWidth: 146
      },
      '& + button': {
        marginLeft: theme.spacing(2)
      }
    }
  },
  DeleteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(80),
    color: theme.palette.warning.main
  },
  RemoveWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(80),
    color: theme.palette.error.main
  },
  ApproveIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(80),
    color: theme.palette.success.main
  },

  colorBoxWrapper: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    transition: 'all 0.3s ease-in-out',
    marginRight: theme.spacing(1.5),
    flex: '0 0 auto'
  },
  colorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.start': {
      justifyContent: 'flex-start'
    }
  },
  specsWrapper: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    '&> label': {
      color: theme.palette.text.primary,
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1.5)
      }
    },
    '& button': {
      lineHeight: 1.3,
      padding: theme.spacing(1.25, 2.75),
      borderRadius: theme.spacing(1.25),
      [theme.breakpoints.up('sm')]: {
        minWidth: 146
      },
      '&.MuiButton-containedSecondary': {
        marginLeft: theme.spacing(2)
      }
    }
  },
  specsSectionWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(3)
  },
  specsSectionTitleWrapper: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center'
  },
  specItem: {
    flex: '1 1 auto',
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& + $specItem': {
      marginLeft: theme.spacing(2)
    }
  },
  specsValuesWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '&> label': {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1.5)
      }
    }
  },
  specsValueWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  VehicleCardImageWrapper: {
    '& .lazy-load-image-background': {
      overflow: 'hidden',
      textAlign: 'center',
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.colors.table,
      '& img': {
        margin: '0 auto',
        width: 'auto',
        maxHeight: '100%'
      }
    }
  },

  fileUploadWrapper: {
    position: 'relative',
    width: '100%',
    height: 40,
    boxShadow: `0px 0px 5px 2px ${alpha(theme.palette.colors.border, 0.8)}`,
    borderRadius: theme.spacing(0.75),

    '&:after': {
      content: 'attr(data-text)',
      fontSize: theme.typography.pxToRem(14),
      position: 'absolute',
      top: 0,
      left: 0,
      background: theme.palette.common.white,
      padding: theme.spacing(1.25, 2),
      display: 'block',
      width: 'calc(100% - 65px)',
      pointerEvents: 'none',
      zIndex: 20,
      height: 40,
      lineHeight: theme.typography.pxToRem(18),
      color: '#999',
      borderRadius: theme.spacing(0.625, 1.25, 1.25, 0.625),
      fontWeight: theme.typography.fontWeightRegular,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '&:before': {
      content: `${theme.direction === 'ltr' ? '\'Upload\'' : '\'إرفاق\''}`,
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'inline-block',
      height: 40,
      transition: 'all 0.3s ease-in-out',
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      zIndex: 25,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(38),
      padding: theme.spacing(0, 3),
      textTransform: 'uppercase',
      pointerEvents: 'none',
      borderRadius: theme.spacing(0, 0.625, 0.625, 0)
    },

    '&:hover': {
      '&:before': {
        background: theme.palette.primary.dark
      }
    },
    '& input': {
      opacity: 0,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 99,
      height: 40,
      margin: 0,
      padding: 0,
      display: 'block',
      cursor: 'pointer',
      width: '100%'
    }
  },
  uploadProgressWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
  },
  uploadProgress: {
    width: 200,
    height: 10,
    marginTop: theme.spacing(10),
    borderRadius: theme.spacing(1),
    '& .MuiLinearProgress-bar': {
      borderRadius: theme.spacing(1)
    }
  },

  deliveryDateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(18),
    padding: theme.spacing(0.5, 1),
    '&.incentive-amount':{
      fontSize: theme.typography.pxToRem(15),
    }
  },
  deliveryDateLabel: {
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(0.75)
  },
  deliveryDateValue: {
    color: theme.palette.primary.main
  },
  gutterTop: {
    marginTop: theme.spacing(1.5)
  },
  hintList: {
    paddingLeft: theme.spacing(2)
  },
  underLine: {
    textDecoration: 'underline'
  },
  RejectHintWrapper: {
    border: `1px solid ${theme.palette.warning.light}`
  },
  noMarTop:{
    marginTop: theme.spacing(0)
  },

  MoneyTransferAccordion: {
    marginTop: theme.spacing(1.5) + 'px !important',
    marginBottom: theme.spacing(0.5) + 'px !important',
    '& #money-transfer-content': {
      width: '100%'
    }
  },
  MoneyTransferHeading: {
    '&.MuiAccordionSummary-root': {
      minHeight: theme.spacing(6),
      backgroundColor: theme.palette.colors.body
    },
    '& .MuiAccordionSummary-content': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  MoneyTransferHeader: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(0.25),
      color: theme.palette.primary.main
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
}))
