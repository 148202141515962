import React from "react";
import { NavLink } from "react-router-dom";
import { NamedRoutes } from "../../../routes/index";
import { useStyles } from "./Styles";
import { useTranslation } from "react-i18next";
import { GeneralConsumer } from "../../../store/GeneralContext";

const FooterNavigation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  let defaultMenu = [
    {
      key: "Landing",
      label: t("MainNavigation.link.item1"),
      link: NamedRoutes.Landing,
    },
    {
      key: "Services",
      label: t("MainNavigation.link.item2"),
      link: NamedRoutes.services,
    },
    {
      key: "About Us",
      label: t("MainNavigation.link.item3"),
      link: NamedRoutes.AboutUs,
    },
    {
      key: "Contact Us",
      label: t("MainNavigation.link.item4"),
      link: NamedRoutes.ContactUs,
    },
  ];
  return (
    <GeneralConsumer>
      {({ toggleMobileMenuDrawer, selectedTab }) => (
        <nav className={classes.footerNavigation}>
          <ul>
            {defaultMenu.map((item, index) => (
              <li
                style={{
                  color: selectedTab === item.link ? "#F06642" : "#565656",
                }}
                key={item.key || index}
              >
                <NavLink
                  exact
                  activeClassName="active"
                  to={item.link}
                  onClick={() => toggleMobileMenuDrawer(false, item.link)}
                >
                    <span style={{color:selectedTab===item.link?'#F06642':'#FFF'}}>{item.label}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </GeneralConsumer>
  );
};

export default FooterNavigation;
