    //@ts-nocheck
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationEng from "./lang/english.json";
import translationAr from "./lang/arabic.json"
const DETECTION_OPTIONS = {
    order: ['localStorage','navigator'],
    caches: ['localStorage']

  };
  const dir = localStorage.getItem('i18nextLng')==='ar' ? "rtl" : "ltr"
  document.documentElement.dir = dir

  if( localStorage.getItem('i18nextLng')===undefined){
    if(dir==="ltr")  localStorage.setItem('i18nextLng','en')
    else localStorage.setItem('i18nextLng','ar')

  }
i18n
  .use(XHR) 
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    load: 'languageOnly',
    detection: DETECTION_OPTIONS,
    lng: localStorage.getItem('i18nextLng')?localStorage.getItem('i18nextLng'):'en',
    
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome
    
    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      en: {
        translations: translationEng
      },
      ar: {
        translations: translationAr
      },
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
    
  });

export default i18n;