import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../useApiFetching";
import userContext from "../../store/userContext/context";
import { getFromStorage, addToStorage } from "../../utils/localStorage";
import Configuration from "../Configuration";

const useRegionsHook = () => {
  const { getRequest } = useApi();
  const user = getFromStorage("@fixPressUser");
  const [loading, setLoading] = useState(false);
  const [regions,   setRegions] = useState([]);
  const { setUserContext } = useContext(userContext);
  const { push } = useHistory();
  const handleChangeRegions = useCallback(
    async (event: any) => {
      let selectedRegion = regions.filter(
        (region: any) => region.id === event.target.value
      );
      let user = getFromStorage("@fixxPressUser");
      user = {
        ...user,
        selectedRegion: selectedRegion[0],
      };
      addToStorage("@fixxPressUser", user);
      setUserContext((old: any) => {
        return {
          ...old,
          selectedRegion: selectedRegion[0],
        };
      });

      window.location.reload();
    },
    [regions, setUserContext]
  );
  const getRegions = useCallback(async () => {
    // let cartTemp=[] as any
    setLoading(true);
    let response = await getRequest(Configuration.endPoints.regions, false);
    if (response?.status?.code === Configuration.statusCode.success) {
      setRegions(response.data);
      let user = getFromStorage("@fixxPressUser");
      if (!user?.selectedRegion) {
        user = {
          ...user,
          selectedRegion: response.data[0],
        };
        setUserContext((old: any) => {
          return {
            ...old,
            selectedRegion: response.data[0],
          };
        });
      }
      // get cart per region and add add it to cache
      if (Configuration.isAuth()) {
        const responseCart = await getRequest(
          `${Configuration.endPoints.getAllCart}`,
          true
        );
        if (responseCart?.status?.code === Configuration.statusCode.success) {
          user={
            ...user,
            cartDetails:responseCart.data ||[]
          }
        } 
      }
      addToStorage("@fixxPressUser", user);
      
    }

         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, push, setUserContext, loading]);
  useEffect(() => {
    getRegions();
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    regions,
    getRegions,
    handleChangeRegions,
    loading,
  };
};

export default useRegionsHook;
