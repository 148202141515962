import { BASE_API, clientConfig, clientSecret } from "../config";
// import { useContext } from 'react';
// import userStore from './../store/context';
export const get = async (
  url: string,
  token?: string,
  lang?: string,
  region?: number
) => {
  const isBlob = "json";
  return await BASE_API.get(url, {
    responseType: isBlob,
    headers: {
      Authorization: `Bearer ${token}`,
      "Accept-Language": lang,
      client: clientConfig,
      "client-secret": clientSecret,
      region: region,
    },
  });
};

export const post = async (
  url: string,
  body?: any,
  token?: string,
  lang?: string,
  region?: number,
) => {
  return await BASE_API.post(url, body, {
    headers: {
      Authorization: "Bearer " + token,
      lang: lang,
      "Accept-Language": lang,
      client: clientConfig,
      "client-secret": clientSecret,
      region: region,
    },
  });
};

export const patch = async (url: string, body?: any, token?: string,lang?: string,
  region?: number,) => {
  return await BASE_API.patch(url, body, {
    headers: {
      Authorization: "Bearer " + token,
      lang: lang,
      "Accept-Language": lang,
      client: clientConfig,
      "client-secret": clientSecret,
      region: region,
    },
  });
};

export const put = async (url: string, body?: any, token?: string,lang?: string,
  region?: number,) => {
  return await BASE_API.put(url, body, {
    headers: {
      Authorization: "Bearer " + token,
      lang: lang,
      "Accept-Language": lang,
      client: clientConfig,
      "client-secret": clientSecret,
      region: region,    },
  });
};

export const deleteReq = async (
  url: string,
  token?: string,
  lang?: string,
  region?: number,
) => {
  // console.log('entered delete')
  return await BASE_API.delete(url, {
    headers: {
      Authorization: "Bearer " + token,
      lang: lang,
      "Accept-Language": lang,
      client: clientConfig,
      "client-secret": clientSecret,
      region: region,
    },
  });
};
