import { makeStyles} from '@material-ui/core/styles'
// import Configuration from '../../services/Configuration'

// const menuHeightLg = 53
export const useStyles = makeStyles((theme) => ({
  mainNavigationWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',  
    // paddingLeft:Configuration.getDocumentDirection()!=='rtl'?'8.4rem':'0px',
    // paddingRight:Configuration.getDocumentDirection()!=='rtl'?'0px':'8.4rem'

  },
  mainNavigation: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      position: 'relative',
      paddingRight: theme.typography.pxToRem(43),
      paddingLeft: theme.typography.pxToRem(43),
 
      
    },
    '& ul': {
      listStyle: 'none',
      overflow: 'hidden',
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      // [theme.breakpoints.up('lg')]: {
      //   height: theme.typography.pxToRem(43),
      //   display: 'flex',
      //   // backgroundColor: theme.palette.primary.light
      // }
    },
    '@media (min-width: 1200px)': {
      '& ul': {
        height: theme.typography.pxToRem(43),
        display: 'flex',
      }
  },
    '& a': {
      color: theme.palette.text.primary,
      padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(20)}`,
      display: 'inline-block',
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
      textDecoration: 'none',
      lineHeight: 1.36,
      fontSize:theme.typography.pxToRem(20),
      [theme.breakpoints.up('lg')]: {
        height: theme.typography.pxToRem(43),
        whiteSpace:'nowrap'
      },
      '&.active': {
        // backgroundColor: alpha(theme.palette.common.black, 0.2)
      },
      '&:hover, &:focus': {
        color: theme.palette.primary.main,
      },
      // '& span': {
      //   color: theme.palette.secondary.main,
      //   fontWeight: theme.typography.fontWeightBold,
      //   paddingTop: theme.spacing(0.25),
      //   display: 'inline-block',
      //   fontSize: theme.typography.pxToRem(24),
      //   lineHeight: theme.typography.pxToRem(14),
      //   [theme.breakpoints.up('lg')]: {
      //     verticalAlign: 'top'
      //   }
      // }
    },
    '& .hidden-item':{
      opacity: 0
    }
  }
}))
