import React, { useState, FC } from "react";
// import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    width: "100%",
    "& .MuiSnackbar-root":{
      left:'0 !important',
      right:'0 !important'
    },
    transition: 'max-height 0.3s ease-in-out',

    "& .MuiSnackbarContent-root": {
      width: "100%",
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "#fff",
      color:"#000",
      textAlign: "center",
      position: "relative",
      height:'80px',
      left:0,
      right:0
    },
    '& .MuiSnackbarContent-message':{
      width:'100%',
      display:'flex',
      alignItems:'center'
    }
  },
  announcementIcon: {
    marginRight: theme.spacing(2),
    color:theme.palette.primary.main,
    fontSize:'5em'
  },

  button: {
    position: "fixed",
    top: "-17px", // adjust as needed
    left: "49%",
    height:'1.5rem',
    transform: "translateX(-50%)",
    margin: theme.spacing(2),
    zIndex: 9999,
  },
  description:{
    color:"#565656",
  },
  title:{
    color:"#565656",
    marginInlineStart:theme.spacing(0.2)
  },
  descriptionWrapper:{
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },  }
}));

export interface Props {
  content: any;
}

const Announcement: FC<Props> = ({ content }) => {
  // const [open, setOpen] = useState(true);
  const [open] = useState(true);

  const classes = useStyles();

  // const handleButtonClick = () => {
  //   setOpen(!open);
  // };

  return (
    <div style={{marginBottom:open?'50px':"0px"}}>
      {open ? (
        <div
          className={classes.root}
          style={{ position: "fixed", zIndex: 1000 }}
        >
          <div
          className={classes.descriptionWrapper}
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E0DFD5",
              color: "white",
              padding: "0 1rem",
            }}
          >
            <div
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: content?.description }}
            ></div>
            <p className={classes.title}>- {content?.title}</p>
          </div>
        </div>
      ) : null}
      {/* <Button
        // color="primary"
        size="small"
        variant="contained"
        onClick={handleButtonClick}
        className={classes.button}
        style={{
          visibility: "visible",
          top: open ? "30px" : "-17px",
          transition: "ease-in 128ms",
          background:'#565656'
        }}
      ></Button> */}
    </div>
  );
};

export default Announcement;
