import { makeStyles} from '@material-ui/core/styles'
import footer from '../../../assets/png/footer.png'
import Configuration from '../../../services/Configuration'
export const useStyles = makeStyles((theme:any) => ({
  footer: {
    backgroundImage:`url(${footer})`,
    padding: theme.spacing(4),
    height:'450px',
    [theme.breakpoints.down("md")]: {
      height:'auto'
    }
  },
  googleWrapper:{
    marginInlineStart:10

  },
 
  footerGridItemNavigation: {},
  footerNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      // maxWidth: 300,
      margin: 'auto',
      marginBottom: theme.spacing(4)
    },
    '& ul': {
      listStyle: 'none',
      margin: theme.spacing(0),
      padding: theme.spacing(0)
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(0.5),
      display: 'inline-block',
      '&:hover,&:focus': {
        opacity: 0.75,
        color: theme.palette.common.white
      }
    }
  },
  footerGridItemInfo: {},
  footerGridItemContactUS: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    },
    '& h3': {
      marginTop: theme.spacing(0),
      lineHeight: 0.7,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(20)
    },
    '& a': {
      marginTop: theme.spacing(0),
      lineHeight: 0.7,
      textDecoration: 'none',
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(36),
      '&:hover,&:focus': {
        color: theme.palette.primary.main
      }
    }
  },
  InfoDetialsWrapper:{
    display:'flex',
    flexDirection:'column',
    flexWrap:'wrap'
   },
   
   InfoDetials:{
    display:'flex',
    alignItems:'center',
    color: theme.palette.common.white,
    margin:theme.spacing(0,0,3,0),
    '& span':{
      margin:Configuration.getDocumentDirection()==='rtl'? theme.spacing(0,2,0,0):theme.spacing(0,0,0,4)
    }
   },
   applicationWrapper:{
    display:'flex',
    width:'100%',
   }
}))
