import React, { useCallback, useContext, useState } from "react";
import {
  Container,
  Grid,
  Toolbar,
  Radio,
  AppBar,
  MenuItem,
  Menu,
  IconButton,
  Select,
  FormControl,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {  md } from "../../../utils/ResponsiveUtility";
import MainNavigation from "../../MainNavigation";
import { useTranslation } from "react-i18next";
import MobileMenu from "../../MobileMenu";
import { useStyles } from "./Styles";
import Logo from "../../Logo";
import useStore from "../../../store/userContext/context";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import PublicIcon from "@material-ui/icons/Public";
import useLanguageHook from "../../../services/GeneralHooks/changeLangHook";
import useLogoutHook from "../../../services/GeneralHooks/logoutHook";
import useRegionsList from "../../../services/GeneralHooks/useRegionsHook";
import { useFromStyles } from "../../../utils/FormStyles";
import Configuration from "../../../services/Configuration";
import { NamedRoutes } from "../../../routes/index";
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { GeneralContext } from "../../../store/GeneralContext";
import Announcment from "../../Announcment";
import useApi from "../../../services/useApiFetching";
import { useLocation } from "react-router-dom";
const Header = ({ location }: any) => {
  const { userState } = useContext(useStore);
  const { handleLangRadioOnChange } = useLanguageHook();
  const { handleLogOut } = useLogoutHook();
  const { regions, handleChangeRegions } = useRegionsList();
  const { push } = useHistory();
  const formClasses = useFromStyles();
 //@ts-ignore
 const { seTabRoute } = useContext(GeneralContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const [width, setWidth] = React.useState(document.body.clientWidth);
  const [state, setState] = React.useState("");
  const pathName=useLocation()
  const Mobile = width < md;
  const DesktopAndUp = width > md;

  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth);
    };
    const resizeHeaderOnScroll = () => {
      let distanceY = window.pageYOffset || document.documentElement.scrollTop;
      let shrinkOn = 50;

      if (distanceY > shrinkOn) {
        setState("shrink");
      } else {
        setState("");
      }
    };
    window.addEventListener("scroll", resizeHeaderOnScroll);
    window.addEventListener("resize", getWidth);
    return () => {
      window.removeEventListener("scroll", resizeHeaderOnScroll);
      window.removeEventListener("resize", getWidth);
    };
  }, [width]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNavigate = (route:any) => {
    push(route);
    seTabRoute(route)
  };

  const [announcmentMessage,setMessage]=useState<any>('')
  // const [loadingAnnouncment,setLoadingAnnouncment]=useState<boolean>(false)
  const { getRequest } = useApi();

  const fetchAnnouncment = useCallback(async () => {
    // setLoadingAnnouncment(true);
    let response = await getRequest(
      `${Configuration.endPoints.announcment}`
    );
    if (response?.status?.code===Configuration.statusCode.success){
      setMessage(response.data)
    }
    // setLoadingAnnouncment(false);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchAnnouncment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AppBar
      position="fixed"
      className={`${classes.header} ${location} ${Mobile ? "shrink" : state}`}
      id="header"
      elevation={0}
    >
    {(announcmentMessage&& announcmentMessage.length>0 &&(pathName.pathname===NamedRoutes.Landing || pathName.pathname===NamedRoutes.AboutUs || pathName.pathname===NamedRoutes.ContactUs)) &&   <Announcment content={announcmentMessage[0]}/>}
      <Toolbar className={classes.headerToolbar}>
        <Container style={{ position: "relative" }} maxWidth="xl">
          {/* <Announcement />  */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.headerGridContainer}
          >
            {DesktopAndUp && (
              <Grid
                style={{ alignItems: "center", justifyContent: "center" }}
                item
                className={classes.headerDesktop}
              >
                <Logo location={location} />
                <MainNavigation />
                <div>
                  <FormControl
                    variant="standard"
                    style={{ display: "contents" }}
                    // className={formClasses.FormItemSelect}
                  >
                    <span>{t("header.location")}</span>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // @ts-ignore
                      value={userState.selectedRegion.id || ""}
                      onChange={handleChangeRegions}
                      label="region"
                    >
                      {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem> */}
                      {regions.map((item: any) => {
                        return (
                          <MenuItem key={item?.id} value={item.id}>
                            {item?.location?.city}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                {
                  <div style={{ position: "relative" }}>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <PublicIcon className={classes.iconWrapper} />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      style={{ position: "absolute", top: 0, left: 0 }}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <div className={classes.languageHeaderWrapper}>
                          <p className={classes.languageText}>
                            {t("header.language")}
                          </p>
                          <div className={classes.languageRadioHeaderWrapper}>
                            <div className={classes.languageRadioWrapper}>
                              <Radio
                                checked={
                                  userState.lang === "en" ||
                                  localStorage.getItem("i18nextLng") === "en"
                                }
                                onChange={handleLangRadioOnChange}
                              />
                              <p className={classes.languageText}>
                                {t("header.language.english")}
                              </p>
                            </div>
                            <div className={classes.languageRadioWrapper}>
                              <Radio
                                checked={
                                  userState.lang === "ar" ||
                                  localStorage.getItem("i18nextLng") === "ar"
                                }
                                onChange={handleLangRadioOnChange}
                              />
                              <p className={classes.languageText}>
                                {t("header.language.arabic")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                      <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                    </Menu>
                  </div>
                }
                {Configuration.isAuth() && (
                  <>
                    <div
                      className={classes.iconWrapper}
                      onClick={() =>handleNavigate(NamedRoutes.CartDetails)}
                    >
                      <ShoppingCartIcon />
                    </div>

                    <AccountCircleIcon
                      className={classes.iconWrapper}
                      onClick={() => handleNavigate(NamedRoutes.Profile)}
                    />
                  </>
                )}
                {Configuration.isAuth() ? (
                  <div
                    onClick={handleLogOut}
                    className={classes.iconWrapper}
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <LogOutIcon />
                  </div>
                ) : (
                  <div style={{ width: "138px" }}>
                    <Button
                      style={{
                        padding: "7px 20px 7px 20px",
                        fontSize: "0.9rem",
                        fontWeight: "600",
                      }}
                      children={t("header.label.getStarted")}
                      variant="contained"
                      onClick={() => {
                        push(NamedRoutes.SignIn);
                      }}
                    />
                  </div>
                )}
              </Grid>
            )}
            {Mobile && (
              <>
                <Logo location={location} />
                {/* {Configuration.isAuth() &&
                return  (
                  <div
                    onClick={handleLogOut}
                    className={classes.iconWrapper}
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    <LogOutIcon />
                  </div>
                ) */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {Configuration.isAuth() ? (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        onClick={handleLogOut}
                        className={classes.iconWrapper}
                        style={{ alignItems: "center", display: "flex" }}
                      >
                        <LogOutIcon />
                      </div>

                      <div
                        className={classes.iconWrapper}
                        onClick={() => push(NamedRoutes.CartDetails)}
                      >
                        <ShoppingCartIcon />
                      </div>

                      <AccountCircleIcon
                        className={classes.iconWrapper}
                        onClick={() => push(NamedRoutes.Profile)}
                      />
                    </div>
                  ) : (
                    <div style={{ width: "138px" }}>
                      <Button
                        style={{
                          padding: "7px 20px 7px 20px",
                          fontSize: "0.9rem",
                          fontWeight: "600",
                        }}
                        children={t("header.label.getStarted")}
                        variant="contained"
                        onClick={() => {
                          push(NamedRoutes.SignIn);
                        }}
                      />
                    </div>
                  )}
              
                  <div style={{ position: "relative" }}>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <PublicIcon className={classes.iconWrapper} />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      style={{ position: "absolute", top: 0, left: 0 }}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <div className={classes.languageHeaderWrapper}>
                          <p className={classes.languageText}>
                            {t("header.language")}
                          </p>
                          <div className={classes.languageRadioHeaderWrapper}>
                            <div className={classes.languageRadioWrapper}>
                              <Radio
                                checked={userState.lang === "en"}
                                onChange={handleLangRadioOnChange}
                              />
                              <p className={classes.languageText}>
                                {t("header.language.english")}
                              </p>
                            </div>
                            <div className={classes.languageRadioWrapper}>
                              <Radio
                                checked={userState.lang === "ar"}
                                onChange={handleLangRadioOnChange}
                              />
                              <p className={classes.languageText}>
                                {t("header.language.arabic")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </MenuItem>

                      <MenuItem
                        className={formClasses.formWrapper}
                        onClick={handleClose}
                      >
                        <div
                          className={
                            formClasses.FormItem + " " + formClasses.FormRow
                          }
                        >
                          <FormControl
                            variant="standard"
                            className={formClasses.FormItemSelect}
                          >
                            <span>{t("header.location")}</span>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              // @ts-ignore
                              value={userState.selectedRegion.id || ""}
                              onChange={handleChangeRegions}
                              label="region"
                            >
                              {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem> */}
                              {regions.map((item: any) => {
                                return (
                                  <MenuItem key={item?.id} value={item.id}>
                                    {item?.location?.city}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                      <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                    </Menu>
                  </div>
                  <MobileMenu />
                </div>
              </>
            )}
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
