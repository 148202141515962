import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: any) => ({
  header: {
    // transition: 'all 0.5s ease-in-out',
    "&.MuiAppBar-root": {
      backgroundColor: theme.palette.colors.header,
      "&.inverse-Header": {
        backgroundColor: theme.palette.colors.header,
        "& $headerDesktop": {
          alignItems: "center",
        },
      },
    },
    // '&.shrink': {
    //   boxShadow: '0 0 5px hsl(0, 0%, 85%)',
    //   transition: 'all 0.5s ease-in-out',
    //   padding: theme.spacing(0)
    // }
  },
  headerToolbar: {
    // minHeight: theme.spacing(8.75),
    padding: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  headerDesktop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  headerGridContainer: {
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
    },
  },
  iconWrapper: {
    color: theme.palette.secondary.main,
    margin: theme.spacing(2),
    height: '24px !important',
    cursor: "pointer",
    "&:hover, &:focus": {
      color: theme.palette.primary.main,
      outline: "none",
    },
  },
  languageHeaderWrapper: {
    width: "220px",
    padding: "15px 10px",
    flexDirection: "column",
    display: "flex",
  },
  languageRadioHeaderWrapper: {
    justifyContent: "space-between",
    display: "flex",
  },
  languageText: {
    color: theme.palette.text.main,
    font: "normal normal 600 14px20px Nunito Sans",
    margin: "0px",
    textAlign: "start"
  },
  languageRadioWrapper: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "row",
  },
}));
