import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../useApiFetching";
import userContext, { initialState } from "../../store/userContext/context";
import { getFromStorage, addToStorage } from "../../utils/localStorage";
import Configuration from "../Configuration";
import { NamedRoutes } from "../../routes";

const useLogoutRequest = () => {
  const { postRequest } = useApi();
  const user = getFromStorage("@fixxPressUser");
  const { setUserContext } = useContext(userContext);
  const { push } = useHistory();

  const handleLogOut = useCallback(async () => {
    let response = await postRequest(Configuration.endPoints.logout, {}, false);
    if (response?.status?.code === Configuration.statusCode.success) {
      delete user.refreshToken;
      delete user.accessToken;
      delete user.accessToken;
      delete user.me;
      delete user.id;
      delete user.cartDetails;

      addToStorage("@fixxPressUser", user);
      setUserContext(initialState);
      push(NamedRoutes.Landing);
    }
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, push, setUserContext]);

  return {
    handleLogOut,
  };
};

export default useLogoutRequest;
