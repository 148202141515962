import React,{FC, useContext} from 'react'
import { Link } from 'react-router-dom'
// import { FormattedMessage } from 'react-intl'
import logo  from '../../assets/images/Logo.svg'
import { NamedRoutes } from '../../routes'
import { useStyles } from './Styles'
import { GeneralContext } from "../../store/GeneralContext";

 interface Props{
  location:any
 }
const Logo:FC<Props> = ({location}) => {
  const classes = useStyles()
  const locationClass = location !== 'Landing' ? 'inner' : ''
  //@ts-ignore
  const { seTabRoute } = useContext(GeneralContext);

  return (
    <div className={`${classes.logo} ${locationClass}`}>
      <Link onClick={()=>{seTabRoute(NamedRoutes.Landing)}} to={NamedRoutes.Landing}>
        <div className={classes.LogoImage}>
          <img height={80} width={150} src={logo} alt='Fixxpress Logo'/> 
        </div>
      </Link>
      {/* {location !== 'home' && <Link to="/" className={classes.innerLogo}>
        <FormattedMessage id='logo.innerLogo'/>
      </Link>} */}
    </div>
  )
}

export default Logo
