import React, { createContext } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { NamedRoutes } from "../routes";

const GeneralContext = createContext({});

const GeneralProvider = ({ children }: any) => {
  const [mobileMenuState, setMobileMenuState] = React.useState(false);
  const [selectedTab, seTabRoute] = React.useState("/");
  const homeRef = React.useRef<any>(null);
  const aboutUs = React.useRef<any>(null);
  const contactUs = React.useRef<any>(null);
  const faqRef = React.useRef<any>(null);
  const location = useLocation();
  // const {push}=useHistory()
  const executeScroll = (ref: any) => {
    switch (ref) {
      case NamedRoutes.Landing:
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        break;
      case NamedRoutes.AboutUs:
        aboutUs.current?.scrollIntoView({
          behavior: "smooth",
          inline: "start",
        });
        break;
      case NamedRoutes.ContactUs:
        contactUs.current?.scrollIntoView({
          behavior: "smooth",
          inline: "start",
        });
        break;
      case selectedTab:
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        break;
    }
  };

  const toggleMobileMenuDrawer = (event: any, location: string) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMobileMenuState(!mobileMenuState);
    seTabRoute(location);
  };

  React.useEffect(() => {
    //@ts-ignore
    if (selectedTab !== "/Services") {
      executeScroll(selectedTab);
    }
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, location]);

  React.useEffect(() => {
    const currentLocation = location.pathname;
    seTabRoute(currentLocation);
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


  const [animation, setAnimation] = React.useState({
    section1: false,
    section2: false,
    section3:false
  });
  const [animatedSections, setAnimatedSections] = React.useState<any>([]);

  const refs = [aboutUs, faqRef,contactUs];
  const sections = ["section1", "section2","section3"];



  const handleScroll = () => {
    refs.forEach((ref, i) => {
      if (ref.current) {
        const top = ref.current.offsetTop - window.innerHeight / 2;
        const bottom = top + ref.current.clientHeight;
        if (window.pageYOffset > top && window.pageYOffset < bottom) {
          if (!animatedSections.includes(sections[i])) {
            setTimeout(() => {
              setAnimation({ ...animation, [sections[i]]: true });
              setAnimatedSections([...animatedSections, sections[i]]);
            }, 40);
          }
          if (ref === aboutUs && selectedTab !== NamedRoutes.AboutUs) {
            seTabRoute(NamedRoutes.AboutUs);
            // push(NamedRoutes.AboutUs)

          } else if (ref === contactUs && selectedTab !== NamedRoutes.ContactUs) {
            // console.log('bbbbbb')
            seTabRoute(NamedRoutes.ContactUs);
            // push(NamedRoutes.ContactUs)
          }
        }
      }
    });
    if (aboutUs.current && window.pageYOffset < aboutUs.current.offsetTop - window.innerHeight / 2 && selectedTab !== NamedRoutes.Landing) {
      seTabRoute(NamedRoutes.Landing);
    }
  };
  

  const debouncedHandleScroll = _.debounce(handleScroll, 50);

  React.useEffect(() => {
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedHandleScroll]);

  const resetAnimation = () => {
    setAnimatedSections([]);
    setAnimation({ section1: false, section2: false ,section3:false});
  };

  React.useEffect(() => {
    resetAnimation();
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  React.useEffect(() => {
    refs.forEach((ref, i) => {
      if (ref.current) {
        const top = ref.current.offsetTop - window.innerHeight / 2;
        const bottom = top + ref.current.clientHeight;
        if (window.pageYOffset > top && window.pageYOffset < bottom) {
          if (!animatedSections.includes(sections[i])) {
            setAnimatedSections([...animatedSections, sections[i]]);
          }
        } else {
          if (animatedSections.includes(sections[i])) {
            setAnimatedSections(
              animatedSections.filter((section: any) => section !== sections[i])
            );
          }
        }
      }
    });
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedSections]);

  React.useEffect(() => {
    Object.keys(animation).forEach((section) => {
      if (!animatedSections.includes(section)) {
        setAnimation({ ...animation, [section]: false });
      }
    });
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedSections]);

  return (
    <GeneralContext.Provider
      value={{
        mobileMenuState,
        setMobileMenuState,
        toggleMobileMenuDrawer,
        selectedTab,
        seTabRoute,
        homeRef,
        aboutUs,
        contactUs,
        faqRef,
        animation,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};
const GeneralConsumer = GeneralContext.Consumer;

export { GeneralProvider, GeneralConsumer, GeneralContext };
