//@ts-nocheck
import { darken, makeStyles } from '@material-ui/core/styles'
import Configuration from '../../../../services/Configuration'
export const useStyles = makeStyles((theme) => ({
  socialMediaWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg':{
      margin:Configuration.getDocumentDirection()==='rtl'? theme.spacing(0,2,0,0):theme.spacing(0,0,0,2)
    },
    marginTop: theme.spacing(2)
  },
  socialMediaLink: {
    textDecoration: 'none',
    color:'transparent',
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(0.5),
    display: 'inline-block',
    width: theme.spacing(3),
    height: theme.spacing(3),
    // backgroundColor: 'red',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(16)
    },
    '&.linkedIn': {
      backgroundColor: '#E8E9EB'
    },
    '&.twitter': {
      backgroundColor: '#E8E9EB'
    },
    '&.youTube': {
      backgroundColor: '#E8E9EB'
    },
    '&.facebook': {
      backgroundColor: '#E8E9EB'
    },
    '&:hover,&:focus': {
      color: theme.palette.common.white,
      '&.linkedIn': {
        backgroundColor: darken('#007bb6', 0.25)
      },
      '&.twitter': {
        backgroundColor: darken('#00aced', 0.25)
      },
      '&.youTube': {
        backgroundColor: darken('#bb0000', 0.25)
      },
      '&.facebook': {
        backgroundColor: darken('#3b5998', 0.25)
      }
    }
  }
}))
