import React, { lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// import LoadingPlaceholder from '../components/LoadingPlaceHolder';
import PrivateRoute from "../utils/AnonynmousRoute";
import Layout from "../components/Layout";
import RouteWrapper from "./RouteWrapper";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { useStyles } from "../components/Layout/Styles";
export const NamedRoutes = {
  Landing: "/",
  AboutUs: "/AboutUs",
  ContactUs: "/ContactUs",
  services: "/Services",
  SignIn: "/signin",
  SignUp: "/signup",
  SignUpSocial: "/signup/socialLogin",
  ForgetPassword: "/forgotpassword",
  Checkout: "/Checkout",
  CartDetails: "/cart",
  Profile: "/profile",
  Privacy:'/privacy',
  TermsAndCondition:'/termsandconditions'

};

const Landing = lazy(() => import("../pages/Landing"));
const Services = lazy(() => import("../pages/Services"));
const Checkout = lazy(() => import("../pages/Checkout"));
const CartDetails = lazy(() => import("../pages/CartDetails"));
const Signin = lazy(() => import("../pages/Auth/SignIn"));
const SignUp = lazy(() => import("../pages/Auth/SignUp"));
const ForgetPassword = lazy(() => import("../pages/Auth/ForgetPassword"));
const Profile = lazy(() => import("../pages/Profile"));
const PrivacyPolicy = lazy(() => import("../pages/Privacy/privacy"));
const TermsAndCondition = lazy(() => import("../pages/TermsAndCondition"));

const AppRoutes = () => (
  <BrowserRouter>
    <React.Suspense fallback={<></>}>
      <Switch>
        <Route  exact path={NamedRoutes.SignIn} component={Signin} />
        <Route exact path={NamedRoutes.SignUp} component={SignUp} />
        <Route
          exact
          path={NamedRoutes.SignUpSocial}
          render={(props) => <SignUp {...props} isSocial={true} />}
        />
        <Route
          exact
          path={NamedRoutes.ForgetPassword}
          component={ForgetPassword}
        />
        
        <Route
          exact
          path={NamedRoutes.Privacy}
          component={PrivacyPolicy}
        />
          <Route
          exact
          path={NamedRoutes.TermsAndCondition}
          component={TermsAndCondition}
        />
        <PrivateRoute exact path={NamedRoutes.Checkout} component={Checkout} />
        <RouteWrapper
          layout={Layout}
          path={NamedRoutes.Profile}
          component={Profile}
        />

        <RouteWrapper
          path={NamedRoutes.services}
          component={Services}
          layout={Layout}
        />
        <RouteWrapper
          path={NamedRoutes.CartDetails}
          component={CartDetails}
          layout={Layout}
        />

        <RouteWrapper
          path={NamedRoutes.Landing}
          component={Landing}
          layout={Layout}
        />
      </Switch>
    </React.Suspense>
  </BrowserRouter>
);

//@ts-ignore
export default withRouter(withStyles(useStyles)(AppRoutes));
