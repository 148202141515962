import React from "react";
import { Grid } from "@material-ui/core";
import FooterNavigation from "./FooterNavigation.js";
import SocialMedia from "./SocialMedia";
import { useStyles } from "./Styles";
// import { FormattedMessage } from 'react-intl'
// import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import apple from "./apple.svg";
import google from "./google.svg";

import TollIcon from "@material-ui/icons/Toll";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <footer className={classes.footer}>
      {/* <Container maxWidth="lg"> */}
      <Grid container>
        <Grid item xs={12} md={12} lg={4}>
          <h4 style={{ color: "white" }}>{t("footer.download.Title")}</h4>
          <div className={classes.applicationWrapper}>
            <a href="https://apps.apple.com/ae/app/fixxpress/id6446465350">
              <img src={apple} alt="mobile app store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.fixpress">
              <img
                className={classes.googleWrapper}
                src={google}
                alt="mobile play store"
              />
            </a>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <h4 style={{ color: "white" }}>{t("footer.location.Title")}</h4>
          <div className={classes.InfoDetialsWrapper}>
            <div className={classes.InfoDetials}>
              <TollIcon />
              <span>
                {" "}
                {t("contatcUs.tollfree")}| {`800-349977377`}
              </span>
            </div>
            <div className={classes.InfoDetials}>
              <WhatsAppIcon />
              <span> {`(+971) 0565388994`}</span>
            </div>
            <div className={classes.InfoDetials}>
              <DateRangeIcon />
              <span> {t("contactUs.workingOur")}</span>
            </div>
            <div className={classes.InfoDetials}>
              <EmailIcon />
              <span> {t("contactUs.mail")}</span>
            </div>
            <div className={classes.InfoDetials}>
              <LocationOnIcon />
              <span> {t("contactUs.Location")}</span>
            </div>
          </div>
          <SocialMedia />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <h4 style={{ color: "white" }}>{t("footer.link.Title")}</h4>
          <FooterNavigation />
        </Grid>
      </Grid>
      {/* </Container> */}
    </footer>
  );
};
export default Footer;
