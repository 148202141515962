import React from "react";

export const FlxLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="272"
    height="155"
    viewBox="0 0 272 155"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f06642" />
        <stop offset="0.07" stopColor="#f07044" />
        <stop offset="0.22" stopColor="#f0844a" />
        <stop offset="0.4" stopColor="#f0924e" />
        <stop offset="0.62" stopColor="#f09a50" />
        <stop offset="1" stopColor="#f09d51" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#f06642" />
        <stop offset="0.24" stopColor="#f07646" />
        <stop offset="0.72" stopColor="#f0924e" />
        <stop offset="1" stopColor="#f09d51" />
      </linearGradient>
    </defs>
    <g id="Logo" transform="translate(-0.577 1.059)">
      <g
        id="Fixxpress_Logo_Final-01"
        data-name="Fixxpress Logo Final-01"
        transform="translate(-0.021 23.6)"
      >
        <g id="Group_149" data-name="Group 149" transform="translate(0 30.203)">
          <path
            id="Path_45"
            data-name="Path 45"
            d="M265,349.615h13.566l-3.674,9.281-.094,26.091H264.9Z"
            transform="translate(-227.288 -331.937)"
            fill="#f06642"
          />
          <path
            id="Path_46"
            data-name="Path 46"
            d="M1095.122,360.538a10.514,10.514,0,0,1-.661,3.7,11.538,11.538,0,0,1-1.815,3.205,10.756,10.756,0,0,1-2.756,2.426,10.432,10.432,0,0,1-3.531,1.366l12.057,13.753h-13.048l-7.52-10.459a.759.759,0,0,0-1.376.444l.039,10.015h-9.892q-.044-3.01-.049-6.006V372.93a8.345,8.345,0,0,1,.685-3.368,8.852,8.852,0,0,1,1.859-2.757,8.741,8.741,0,0,1,2.757-1.859,8.409,8.409,0,0,1,3.368-.681h7.3a2.681,2.681,0,0,0,1.884-4.591,2.621,2.621,0,0,0-1.884-.779h-16.011l-3.718-9.281h21.382a10.759,10.759,0,0,1,4.261.848,10.878,10.878,0,0,1,6.667,10.079Z"
            transform="translate(-911.908 -331.937)"
            fill="#f09d51"
          />
          <path
            id="Path_47"
            data-name="Path 47"
            d="M1519.73,357.715a1.855,1.855,0,0,0,1.366,1.741,20.532,20.532,0,0,0,3.413,1.011c1.366.3,2.84.651,4.428,1.06a16.434,16.434,0,0,1,4.428,1.859,10.541,10.541,0,0,1,3.417,3.393,10.421,10.421,0,0,1,1.366,5.676,8.76,8.76,0,0,1-1.248,4.734,10.84,10.84,0,0,1-3.225,3.319,14.958,14.958,0,0,1-4.379,1.977,17.942,17.942,0,0,1-4.758.661,23.058,23.058,0,0,1-4.285-.4,20.117,20.117,0,0,1-4.073-1.223,19.911,19.911,0,0,1-3.7-2.046,17.07,17.07,0,0,1-3.156-2.875l6.593-7.017a12.17,12.17,0,0,0,3.723,3.58,8.983,8.983,0,0,0,4.758,1.317,6.752,6.752,0,0,0,1.317-.143,5,5,0,0,0,1.317-.449,3.276,3.276,0,0,0,1.036-.8,1.858,1.858,0,0,0,.424-1.248,1.493,1.493,0,0,0-.779-1.3,8.762,8.762,0,0,0-2.027-.893q-1.25-.4-2.875-.755t-3.3-.873a26.425,26.425,0,0,1-3.3-1.272,11.062,11.062,0,0,1-2.875-1.908,8.892,8.892,0,0,1-2.027-2.826,9.5,9.5,0,0,1-.779-4.029,9.632,9.632,0,0,1,1.154-4.759,11.15,11.15,0,0,1,3.013-3.486,13.506,13.506,0,0,1,4.216-2.145,16.661,16.661,0,0,1,12.294,1.011,26.454,26.454,0,0,1,6.312,4.665l-6.031,6.263a14.732,14.732,0,0,0-3.531-3.156,8.637,8.637,0,0,0-4.522-1.085,8.492,8.492,0,0,0-1.223.094,4.021,4.021,0,0,0-1.223.375,2.514,2.514,0,0,0-.917.755,1.9,1.9,0,0,0-.355,1.179Z"
            transform="translate(-1295.03 -329.582)"
            fill="#f09d51"
          />
          <path
            id="Path_48"
            data-name="Path 48"
            d="M1727.36,357.715a1.855,1.855,0,0,0,1.366,1.741,20.532,20.532,0,0,0,3.413,1.011c1.366.3,2.84.651,4.428,1.06a16.43,16.43,0,0,1,4.428,1.859,10.539,10.539,0,0,1,3.417,3.393,10.422,10.422,0,0,1,1.366,5.676,8.762,8.762,0,0,1-1.247,4.734,10.845,10.845,0,0,1-3.225,3.319,14.962,14.962,0,0,1-4.379,1.977,17.945,17.945,0,0,1-4.759.661,23.053,23.053,0,0,1-4.285-.4,19.876,19.876,0,0,1-7.772-3.269,17.08,17.08,0,0,1-3.156-2.875l6.593-7.017a12.171,12.171,0,0,0,3.723,3.58,8.984,8.984,0,0,0,4.759,1.317,6.75,6.75,0,0,0,1.317-.143,5,5,0,0,0,1.317-.449,3.273,3.273,0,0,0,1.035-.8,1.856,1.856,0,0,0,.424-1.248,1.492,1.492,0,0,0-.779-1.3,8.75,8.75,0,0,0-2.027-.893q-1.25-.4-2.875-.755t-3.3-.873a26.42,26.42,0,0,1-3.3-1.272,11.056,11.056,0,0,1-2.875-1.908,8.885,8.885,0,0,1-2.027-2.826,9.5,9.5,0,0,1-.779-4.029,9.631,9.631,0,0,1,1.154-4.759,11.156,11.156,0,0,1,3.013-3.486,13.508,13.508,0,0,1,4.216-2.145,16.66,16.66,0,0,1,12.294,1.011,26.462,26.462,0,0,1,6.312,4.665l-6.031,6.263a14.724,14.724,0,0,0-3.531-3.156,8.634,8.634,0,0,0-4.522-1.085,8.481,8.481,0,0,0-1.223.094,4.014,4.014,0,0,0-1.223.375,2.511,2.511,0,0,0-.917.755,1.9,1.9,0,0,0-.355,1.179Z"
            transform="translate(-1473.181 -329.582)"
            fill="#f09d51"
          />
          <g
            id="Group_147"
            data-name="Group 147"
            transform="translate(186.503 17.678)"
          >
            <path
              id="Path_49"
              data-name="Path 49"
              d="M1338.684,456.825h-13.117a2.069,2.069,0,0,1-2.071-2.047h0a2.07,2.07,0,0,1,2.071-2.1h11.327l-.049-8.526h-13.472a10.731,10.731,0,0,0-3.955.705,9.162,9.162,0,0,0-3.087,1.977,9.069,9.069,0,0,0-2.027,3.038,10.043,10.043,0,0,0-.73,3.886l.049,12.338h25.1l-.049-9.28Z"
              transform="translate(-1313.574 -430.734)"
              fill="#f09d51"
            />
            <path
              id="Path_50"
              data-name="Path 50"
              d="M1313.644,349.615v9.276h26.885l3.674-9.276Z"
              transform="translate(-1313.634 -349.615)"
              fill="#f09d51"
            />
          </g>
          <g id="Group_148" data-name="Group 148">
            <path
              id="Path_51"
              data-name="Path 51"
              d="M6.582,225.1l-.552,1.139L.66,237.231H52.891L58.814,225.1Z"
              transform="translate(-0.561 -225.1)"
              fill="#f06642"
            />
            <path
              id="Path_52"
              data-name="Path 52"
              d="M15.76,349.58a17.01,17.01,0,0,0-6.3,1.139,15.135,15.135,0,0,0-5.01,3.19,14.317,14.317,0,0,0-3.3,4.9,16.247,16.247,0,0,0-1.179,6.263q0,5.008.039,9.946t.039,9.946H14.108V363.319a1.808,1.808,0,0,1,1.81-1.81h0v.01H31.131l4.724-11.929H15.76Z"
              transform="translate(0.035 -331.907)"
              fill="#f06642"
            />
          </g>
          <path
            id="Path_53"
            data-name="Path 53"
            d="M523.63,390.258H429.784L413.2,366.805l5.572-7.915h26.012a3.292,3.292,0,0,1,3.294,3.25,3.2,3.2,0,0,1-1.144,2.5,3.269,3.269,0,0,1-2.16.8h-6.682a8.408,8.408,0,0,0-3.368.681,8.71,8.71,0,0,0-4.616,4.616,8.411,8.411,0,0,0-.685,3.368l.049,10.878h9.892l-.044-9.724a.728.728,0,0,1,.73-.73h5.627a11.874,11.874,0,0,0,4.778-.966A12.315,12.315,0,0,0,457,366.993a12.149,12.149,0,0,0,.967-4.8v-.33a11.875,11.875,0,0,0-.967-4.778,12.241,12.241,0,0,0-2.638-3.886,12.56,12.56,0,0,0-3.911-2.614,11.975,11.975,0,0,0-4.778-.967H413.763l-6.356,9.044L401,349.615H380.832l-6.356,9.044-6.406-9.044H356.58l11.963,16.909-12.954,18.463h11.49l7.254-10.316,7.3,10.316h18.384l7.254-10.316L424.143,398.4H570.876l3.974-8.141H523.635Zm-132.822-8.541L380.26,366.805l10.681-15.168,10.528,14.882-10.661,15.2Z"
            transform="translate(-305.098 -331.937)"
            fill="url(#linear-gradient)"
          />
        </g>
        <g id="Group_150" data-name="Group 150" transform="translate(59.115 0)">
          <path
            id="Path_54"
            data-name="Path 54"
            d="M497.4,175a5.2,5.2,0,0,1,0-10.39h4.818c-.163-.271-.395-.636-.7-1.05a16.311,16.311,0,0,0-2.189-2.352,11.028,11.028,0,0,0-6.475-2.165,10.515,10.515,0,0,0-7.989,3.679v-.01a2.9,2.9,0,0,1-.542.444,2.8,2.8,0,0,1-.74.321,2.712,2.712,0,0,1-.715.094H443.2a31.476,31.476,0,0,0-11.628,2.076,27.227,27.227,0,0,0-8.975,5.691,26.884,26.884,0,0,0-6.159,8.787c-.025.064-.064.158-.1.247h15.874c.054-.138.1-.276.163-.409a7.084,7.084,0,0,1,1.484-2.3,7.323,7.323,0,0,1,1.455-1.154,7.654,7.654,0,0,1,3.915-1.036H451.3v.02h31.111a3.51,3.51,0,0,1,1.213.286,3.584,3.584,0,0,1,1.129.789v-.015a10.674,10.674,0,0,0,8.176,3.817,10.564,10.564,0,0,0,4.468-.991,12.4,12.4,0,0,0,1.558-.888,14.043,14.043,0,0,0,1.874-1.617,6.077,6.077,0,0,0,.434-.493,5.379,5.379,0,0,0,.37-.547l.493-.843-4.709.01H497.4Z"
            transform="translate(-416.336 -138.216)"
            fill="#585859"
          />
          <path
            id="Path_55"
            data-name="Path 55"
            d="M538.05,24.078a6.047,6.047,0,0,0-1.529-1.326,12.853,12.853,0,0,0-5.119-1.46c-1.292-.192-3.161-.518-5.464-1.09q-5.178-1.331-10.282-3.057c-10.365-3.5-21.579-5.2-28.981-4.68-5.7.4-15.159,2.224-26.352,10.287-.833,0-2.051.025-3.521.148a25.249,25.249,0,0,0-7.476,1.45,13.083,13.083,0,0,0-3.585,1.879,15.455,15.455,0,0,0-2.771,2.826c-.907,1.223-3.61,7.288-7.012,16.317q.8-.9,1.672-1.741a29.816,29.816,0,0,1,9.818-6.238,34.137,34.137,0,0,1,12.575-2.254H499.7a.137.137,0,0,0,.044,0,.1.1,0,0,0,.049-.02c.015-.01.025-.02.039-.03a.593.593,0,0,0,.054-.059,13.214,13.214,0,0,1,7.4-4.192,13.055,13.055,0,0,1,2.4-.222,13.575,13.575,0,0,1,7.994,2.653,18.177,18.177,0,0,1,2.747,2.9c.3.4.587.833.848,1.267a2.206,2.206,0,0,1,.143.286h13.329l4.3-11.495a6.584,6.584,0,0,0-1-2.15Z"
            transform="translate(-433.173 -12.372)"
            fill="url(#linear-gradient-2)"
          />
        </g>
        <g
          id="Group_151"
          data-name="Group 151"
          transform="translate(118.315 99.304)"
        >
          <path
            id="Path_56"
            data-name="Path 56"
            d="M838.652,712.282l-.276,1.7a2.485,2.485,0,0,0-.769-.464,2.458,2.458,0,0,0-.868-.158,1.646,1.646,0,0,0-1.262.533,1.933,1.933,0,0,0-.5,1.366,1.539,1.539,0,0,0,.419,1.159,1.615,1.615,0,0,0,1.179.409,2.964,2.964,0,0,0,.675-.079,4.492,4.492,0,0,0,.73-.242l-.256,1.6a4.018,4.018,0,0,1-.7.153,6.138,6.138,0,0,1-.824.054,2.816,2.816,0,0,1-2.115-.819,2.991,2.991,0,0,1-.794-2.17,3.91,3.91,0,0,1,.2-1.248,3.3,3.3,0,0,1,.6-1.075,3.021,3.021,0,0,1,1.159-.863,3.928,3.928,0,0,1,1.553-.3,4.006,4.006,0,0,1,.947.109,4.1,4.1,0,0,1,.9.33Z"
            transform="translate(-833.297 -711.833)"
            fill="#585859"
          />
          <path
            id="Path_57"
            data-name="Path 57"
            d="M866.883,719.084l3.087-6.169h2.066l1.312,6.169h-1.672l-.247-1.164h-2.16l-.592,1.164Zm3-2.535h1.3l-.281-1.44c-.03-.143-.049-.271-.064-.385a2.457,2.457,0,0,1-.025-.316q-.089.252-.163.444c-.049.128-.089.222-.118.286Z"
            transform="translate(-862.114 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_58"
            data-name="Path 58"
            d="M916.416,719.084h-1.637l.972-6.169h1.642a8.457,8.457,0,0,1,1.1.054,2.182,2.182,0,0,1,.626.178,1.615,1.615,0,0,1,.71.616,1.836,1.836,0,0,1,.242.957,1.858,1.858,0,0,1-.459,1.282,2.042,2.042,0,0,1-1.257.646l1.5,2.436h-1.879l-1.193-2.357Zm.528-3.348h.518a1.124,1.124,0,0,0,.75-.222.808.808,0,0,0,.261-.646.672.672,0,0,0-.246-.577,1.316,1.316,0,0,0-.784-.177h-.246l-.256,1.622Z"
            transform="translate(-903.21 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_59"
            data-name="Path 59"
            d="M972.32,716.01a2.987,2.987,0,0,0,.74.607,1.5,1.5,0,0,0,.725.187.752.752,0,0,0,.5-.153.493.493,0,0,0,.188-.4c0-.227-.252-.468-.75-.73l-.113-.059a3,3,0,0,1-1-.73,1.368,1.368,0,0,1-.271-.853,1.959,1.959,0,0,1,.617-1.494,2.216,2.216,0,0,1,1.593-.582,2.672,2.672,0,0,1,.986.182,3.192,3.192,0,0,1,.9.537l-.71,1.218a2.921,2.921,0,0,0-.607-.424,1.176,1.176,0,0,0-.508-.138.626.626,0,0,0-.419.143.452.452,0,0,0-.168.355c0,.173.212.37.631.587.148.074.266.138.345.187a2.556,2.556,0,0,1,.9.73,1.623,1.623,0,0,1,.251.932,1.981,1.981,0,0,1-.685,1.583,2.767,2.767,0,0,1-1.869.592,2.581,2.581,0,0,1-1.159-.266,2.948,2.948,0,0,1-.971-.779l.853-1.233Z"
            transform="translate(-951.845 -711.803)"
            fill="#585859"
          />
          <path
            id="Path_60"
            data-name="Path 60"
            d="M1010.385,717.743h1.908l-.217,1.376h-3.555l.971-6.169h3.555l-.222,1.425h-1.908l-.158,1.006h1.77l-.207,1.341h-1.78l-.163,1.026Z"
            transform="translate(-983.643 -712.787)"
            fill="#585859"
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M1043.293,719.084h-1.637l.972-6.169h1.642a8.454,8.454,0,0,1,1.1.054,2.184,2.184,0,0,1,.626.178,1.615,1.615,0,0,1,.71.616,1.834,1.834,0,0,1,.242.957,1.857,1.857,0,0,1-.458,1.282,2.042,2.042,0,0,1-1.258.646l1.5,2.436h-1.879l-1.193-2.357Zm.528-3.348h.518a1.126,1.126,0,0,0,.75-.222.808.808,0,0,0,.261-.646.673.673,0,0,0-.247-.577,1.317,1.317,0,0,0-.784-.177h-.247l-.256,1.622Z"
            transform="translate(-1012.073 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_62"
            data-name="Path 62"
            d="M1082.927,719.083l-1.642-6.169h1.751l.759,3.526c.02.089.035.192.049.306s.025.247.04.395c.054-.153.1-.3.153-.419s.089-.227.118-.3l1.637-3.511h1.834l-3.269,6.169Z"
            transform="translate(-1046.076 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_63"
            data-name="Path 63"
            d="M1123.276,719.084l.976-6.169h1.682l-.977,6.169Z"
            transform="translate(-1082.106 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_64"
            data-name="Path 64"
            d="M1151.416,712.282l-.276,1.7a2.485,2.485,0,0,0-.769-.464,2.459,2.459,0,0,0-.868-.158,1.646,1.646,0,0,0-1.262.533,1.933,1.933,0,0,0-.5,1.366,1.54,1.54,0,0,0,.419,1.159,1.615,1.615,0,0,0,1.179.409,2.962,2.962,0,0,0,.675-.079,4.493,4.493,0,0,0,.73-.242l-.256,1.6a4.018,4.018,0,0,1-.7.153,6.134,6.134,0,0,1-.823.054,2.816,2.816,0,0,1-2.115-.819,2.991,2.991,0,0,1-.794-2.17,3.911,3.911,0,0,1,.2-1.248,3.3,3.3,0,0,1,.6-1.075,3.02,3.02,0,0,1,1.159-.863,3.931,3.931,0,0,1,1.553-.3,4.008,4.008,0,0,1,.947.109,4.1,4.1,0,0,1,.9.33Z"
            transform="translate(-1101.655 -711.833)"
            fill="#585859"
          />
          <path
            id="Path_65"
            data-name="Path 65"
            d="M1186.165,717.743h1.909l-.217,1.376H1184.3l.971-6.169h3.556l-.222,1.425H1186.7l-.158,1.006h1.77l-.207,1.341h-1.78l-.162,1.026Z"
            transform="translate(-1134.466 -712.787)"
            fill="#585859"
          />
          <path
            id="Path_66"
            data-name="Path 66"
            d="M1216.14,716.01a2.99,2.99,0,0,0,.74.607,1.5,1.5,0,0,0,.725.187.752.752,0,0,0,.5-.153.494.494,0,0,0,.187-.4c0-.227-.251-.468-.749-.73l-.114-.059a3,3,0,0,1-1-.73,1.366,1.366,0,0,1-.271-.853,1.959,1.959,0,0,1,.616-1.494,2.217,2.217,0,0,1,1.593-.582,2.671,2.671,0,0,1,.986.182,3.2,3.2,0,0,1,.9.537l-.71,1.218a2.926,2.926,0,0,0-.607-.424,1.177,1.177,0,0,0-.508-.138.626.626,0,0,0-.419.143.452.452,0,0,0-.168.355c0,.173.212.37.631.587.148.074.266.138.345.187a2.551,2.551,0,0,1,.9.73,1.622,1.622,0,0,1,.251.932,1.98,1.98,0,0,1-.685,1.583,2.767,2.767,0,0,1-1.869.592,2.581,2.581,0,0,1-1.159-.266,2.95,2.95,0,0,1-.971-.779l.853-1.233Z"
            transform="translate(-1161.049 -711.803)"
            fill="#585859"
          />
          <path
            id="Path_67"
            data-name="Path 67"
            d="M1266.234,719.084l3.087-6.169h2.066l1.312,6.169h-1.672l-.247-1.164h-2.16l-.592,1.164Zm3-2.535h1.3l-.281-1.44c-.029-.143-.049-.271-.064-.385a2.5,2.5,0,0,1-.025-.316q-.089.252-.163.444c-.049.128-.089.222-.118.286Z"
            transform="translate(-1204.767 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_68"
            data-name="Path 68"
            d="M1315.282,719.084l.749-4.739h-1.381l.237-1.43h4.359l-.237,1.43h-1.317l-.75,4.739h-1.657Z"
            transform="translate(-1246.31 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_69"
            data-name="Path 69"
            d="M1363.745,719.084l.434-2.752-1.736-3.417h1.839l.676,1.578c.034.084.074.173.113.276s.074.212.113.326c.074-.178.138-.316.183-.409a1.164,1.164,0,0,1,.113-.207l1.095-1.558h1.9l-2.633,3.432-.429,2.737h-1.662Z"
            transform="translate(-1287.316 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_70"
            data-name="Path 70"
            d="M1404.827,718.417a3.711,3.711,0,0,1-1.2-.182,2.8,2.8,0,0,1-.961-.542,2.607,2.607,0,0,1-.691-.957,3.043,3.043,0,0,1-.246-1.208,3.822,3.822,0,0,1,.212-1.292,3.422,3.422,0,0,1,.636-1.09,3.273,3.273,0,0,1,1.2-.893,3.8,3.8,0,0,1,1.538-.311,3.167,3.167,0,0,1,2.249.8,2.714,2.714,0,0,1,.858,2.081,3.836,3.836,0,0,1-.217,1.292,3.366,3.366,0,0,1-.636,1.09,3.407,3.407,0,0,1-1.2.9,3.72,3.72,0,0,1-1.533.306Zm.38-4.961a1.667,1.667,0,0,0-1.262.542,1.89,1.89,0,0,0-.508,1.346,1.588,1.588,0,0,0,.409,1.139,1.419,1.419,0,0,0,1.085.424,1.646,1.646,0,0,0,1.257-.562,1.952,1.952,0,0,0,.518-1.371,1.5,1.5,0,0,0-.419-1.095,1.444,1.444,0,0,0-1.075-.429Z"
            transform="translate(-1321.021 -711.922)"
            fill="#585859"
          />
          <path
            id="Path_71"
            data-name="Path 71"
            d="M1455.209,712.919h1.657l-.493,3.1c-.039.237-.064.419-.079.552a3.129,3.129,0,0,0-.02.35.827.827,0,0,0,.222.616.84.84,0,0,0,.621.222q.954,0,1.351-2.535l.01-.059.355-2.244h1.657l-.552,3.5h0a5.089,5.089,0,0,1-.454,1.612,2.279,2.279,0,0,1-.976.917,3.326,3.326,0,0,1-1.484.316,2.782,2.782,0,0,1-1.771-.528,1.7,1.7,0,0,1-.666-1.405,6.188,6.188,0,0,1,.049-.7c.034-.291.084-.656.153-1.09l.419-2.628Z"
            transform="translate(-1366.378 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_72"
            data-name="Path 72"
            d="M1499.71,719.084h-1.637l.971-6.169h1.642a8.457,8.457,0,0,1,1.1.054,2.18,2.18,0,0,1,.626.178,1.615,1.615,0,0,1,.71.616,1.836,1.836,0,0,1,.242.957,1.858,1.858,0,0,1-.459,1.282,2.041,2.041,0,0,1-1.257.646l1.5,2.436h-1.879l-1.194-2.357Zm.528-3.348h.518a1.125,1.125,0,0,0,.75-.222.808.808,0,0,0,.261-.646.672.672,0,0,0-.247-.577,1.317,1.317,0,0,0-.784-.177h-.246l-.256,1.622Z"
            transform="translate(-1403.689 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_73"
            data-name="Path 73"
            d="M1556.809,719.084l.967-6.169h1.06a14.966,14.966,0,0,1,1.7.064,2.535,2.535,0,0,1,.824.227,2.356,2.356,0,0,1,1.045,1.011,3.325,3.325,0,0,1,.36,1.588,3.492,3.492,0,0,1-.32,1.553,2.975,2.975,0,0,1-.981,1.129,3.119,3.119,0,0,1-1.1.459,11.657,11.657,0,0,1-2.13.138H1556.8Zm1.869-1.376c.113.01.2.015.256.015h.153a2.081,2.081,0,0,0,1.5-.5,1.948,1.948,0,0,0,.513-1.46,1.5,1.5,0,0,0-.375-1.139,1.731,1.731,0,0,0-1.213-.355h-.311l-.528,3.432Z"
            transform="translate(-1454.083 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_74"
            data-name="Path 74"
            d="M1607.316,718.417a3.708,3.708,0,0,1-1.2-.182,2.806,2.806,0,0,1-.962-.542,2.608,2.608,0,0,1-.691-.957,3.047,3.047,0,0,1-.246-1.208,3.816,3.816,0,0,1,.212-1.292,3.418,3.418,0,0,1,.636-1.09,3.271,3.271,0,0,1,1.2-.893,3.8,3.8,0,0,1,1.539-.311,3.167,3.167,0,0,1,2.249.8,2.715,2.715,0,0,1,.858,2.081,3.845,3.845,0,0,1-.217,1.292,3.372,3.372,0,0,1-.636,1.09,3.414,3.414,0,0,1-1.2.9,3.722,3.722,0,0,1-1.534.306Zm.38-4.961a1.667,1.667,0,0,0-1.262.542,1.89,1.89,0,0,0-.508,1.346,1.588,1.588,0,0,0,.409,1.139,1.418,1.418,0,0,0,1.085.424,1.647,1.647,0,0,0,1.257-.562,1.953,1.953,0,0,0,.518-1.371,1.494,1.494,0,0,0-.419-1.095,1.444,1.444,0,0,0-1.075-.429Z"
            transform="translate(-1494.761 -711.922)"
            fill="#585859"
          />
          <path
            id="Path_75"
            data-name="Path 75"
            d="M1659.623,718.417a3.711,3.711,0,0,1-1.2-.182,2.805,2.805,0,0,1-.962-.542,2.6,2.6,0,0,1-.69-.957,3.037,3.037,0,0,1-.247-1.208,3.816,3.816,0,0,1,.212-1.292,3.413,3.413,0,0,1,.636-1.09,3.272,3.272,0,0,1,1.2-.893,3.8,3.8,0,0,1,1.539-.311,3.168,3.168,0,0,1,2.249.8,2.714,2.714,0,0,1,.858,2.081,3.842,3.842,0,0,1-.217,1.292,3.368,3.368,0,0,1-.636,1.09,3.411,3.411,0,0,1-1.2.9,3.72,3.72,0,0,1-1.533.306Zm.38-4.961a1.667,1.667,0,0,0-1.262.542,1.89,1.89,0,0,0-.508,1.346,1.589,1.589,0,0,0,.409,1.139,1.419,1.419,0,0,0,1.085.424,1.647,1.647,0,0,0,1.258-.562,1.953,1.953,0,0,0,.518-1.371,1.5,1.5,0,0,0-.419-1.095,1.446,1.446,0,0,0-1.075-.429Z"
            transform="translate(-1539.641 -711.922)"
            fill="#585859"
          />
          <path
            id="Path_76"
            data-name="Path 76"
            d="M1709.076,719.084h-1.637l.971-6.169h1.642a8.457,8.457,0,0,1,1.1.054,2.181,2.181,0,0,1,.626.178,1.617,1.617,0,0,1,.71.616,1.836,1.836,0,0,1,.241.957,1.858,1.858,0,0,1-.459,1.282,2.041,2.041,0,0,1-1.257.646l1.5,2.436h-1.879l-1.193-2.357-.37,2.357Zm.527-3.348h.518a1.124,1.124,0,0,0,.75-.222.808.808,0,0,0,.261-.646.672.672,0,0,0-.247-.577,1.316,1.316,0,0,0-.784-.177h-.246l-.256,1.622Z"
            transform="translate(-1583.33 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_77"
            data-name="Path 77"
            d="M1746.363,716.01a2.992,2.992,0,0,0,.74.607,1.5,1.5,0,0,0,.725.187.753.753,0,0,0,.5-.153.494.494,0,0,0,.187-.4c0-.227-.251-.468-.75-.73-.049-.025-.084-.044-.114-.059a3,3,0,0,1-1-.73,1.366,1.366,0,0,1-.271-.853,1.96,1.96,0,0,1,.616-1.494,2.217,2.217,0,0,1,1.593-.582,2.671,2.671,0,0,1,.986.182,3.074,3.074,0,0,1,.9.537l-.71,1.218a2.917,2.917,0,0,0-.607-.424,1.176,1.176,0,0,0-.508-.138.627.627,0,0,0-.419.143.452.452,0,0,0-.167.355c0,.173.212.37.631.587.148.074.266.138.345.187a2.557,2.557,0,0,1,.9.73,1.623,1.623,0,0,1,.251.932,1.981,1.981,0,0,1-.685,1.583,2.768,2.768,0,0,1-1.869.592,2.581,2.581,0,0,1-1.159-.266,2.952,2.952,0,0,1-.971-.779l.853-1.233Z"
            transform="translate(-1615.992 -711.803)"
            fill="#585859"
          />
          <path
            id="Path_78"
            data-name="Path 78"
            d="M1784.793,719.084l.75-4.739h-1.381l.237-1.43h4.359l-.236,1.43H1787.2l-.749,4.739H1784.8Z"
            transform="translate(-1649.16 -712.757)"
            fill="#585859"
          />
          <path
            id="Path_79"
            data-name="Path 79"
            d="M1817.6,717.743h1.909l-.217,1.376h-3.556l.972-6.169h3.555l-.222,1.425h-1.908l-.158,1.006h1.77l-.207,1.341h-1.781l-.162,1.026Z"
            transform="translate(-1676.249 -712.787)"
            fill="#585859"
          />
          <path
            id="Path_80"
            data-name="Path 80"
            d="M1848.838,719.084l.981-6.169h1.692a6.879,6.879,0,0,1,1.055.059,2.152,2.152,0,0,1,.617.192,1.6,1.6,0,0,1,.705.656,1.989,1.989,0,0,1,.232,1,2.313,2.313,0,0,1-.251,1.07,1.857,1.857,0,0,1-.681.759,1.785,1.785,0,0,1-.6.222,6.386,6.386,0,0,1-1.085.064h-.69l-.345,2.15h-1.637Zm2.189-3.462h.158a2.368,2.368,0,0,0,1.085-.168.607.607,0,0,0,.281-.572.546.546,0,0,0-.242-.5,1.441,1.441,0,0,0-.759-.158h-.3l-.222,1.4Z"
            transform="translate(-1704.65 -712.757)"
            fill="#585859"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const FB_Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="facebook"
      d="M24,12.073A12,12,0,1,0,10.125,24V15.563H7.078v-3.49h3.047V9.414c0-3.026,1.792-4.7,4.532-4.7a18.164,18.164,0,0,1,2.686.238V7.925H15.831a1.74,1.74,0,0,0-1.956,1.886v2.265H17.2l-.532,3.49h-2.8V24A12.054,12.054,0,0,0,24,12.073Z"
      fill="#ededed"
      opacity="0.15"
    />
  </svg>
);
export const INS_Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="instagram-round-icon"
      d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0ZM9.193,5.413h5.615a3.818,3.818,0,0,1,3.83,3.779v5.6a3.813,3.813,0,0,1-3.83,3.779H9.193a3.813,3.813,0,0,1-3.83-3.779v-5.6A3.817,3.817,0,0,1,9.193,5.413Zm2.771,3.031A3.714,3.714,0,1,1,8.25,12.158,3.714,3.714,0,0,1,11.964,8.444Zm0,1.253a2.455,2.455,0,1,1-2.455,2.455A2.458,2.458,0,0,1,11.964,9.7Zm3.592-1.771a.6.6,0,1,1-.6.6A.6.6,0,0,1,15.556,7.926Zm-5.9-1.44h4.693a3.2,3.2,0,0,1,3.2,3.189V14.4a3.2,3.2,0,0,1-3.2,3.189H9.653A3.2,3.2,0,0,1,6.45,14.4V9.675A3.2,3.2,0,0,1,9.653,6.486Z"
      fill="#ededed"
      fillRule="evenodd"
      opacity="0.15"
    />
  </svg>
);
export const TWITTER_Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="twitter-round-icon"
      d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm6.479,7.976a5.152,5.152,0,0,1-1.526.418,2.66,2.66,0,0,0,1.166-1.469,5.494,5.494,0,0,1-1.684.648,2.663,2.663,0,0,0-4.6,1.821,2.78,2.78,0,0,0,.065.6A7.543,7.543,0,0,1,6.421,7.22a2.663,2.663,0,0,0,.821,3.549,2.7,2.7,0,0,1-1.2-.331v.036A2.657,2.657,0,0,0,8.17,13.08a2.815,2.815,0,0,1-.7.094,2.347,2.347,0,0,1-.5-.05,2.669,2.669,0,0,0,2.484,1.85,5.337,5.337,0,0,1-3.3,1.137,6.112,6.112,0,0,1-.633-.036A7.561,7.561,0,0,0,17.154,9.7c0-.115,0-.23-.007-.346a5.346,5.346,0,0,0,1.325-1.375Z"
      fill="#ededed"
      fillRule="evenodd"
      opacity="0.15"
    />
  </svg>
);
export const TIKTOK_Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="snapchat-round-icon"
      d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm3.83,10.74c.619-3.088-.583-5.665-3.837-5.694-2.325-.014-4.442,1.5-3.866,5.478.072.5-.166.439-.54.353-.31-.065-.626-.137-.936-.2a.649.649,0,0,0-.346.166c-.446.475.454.842.792.972.446.166.936.324.871.849a2.492,2.492,0,0,1-.173.619,4.344,4.344,0,0,1-2.613,2.282c-.554.158-.835.223.007.684a4.3,4.3,0,0,0,1.476.41c.086,1.634.705.583,2.044.857a2.522,2.522,0,0,1,.54.144c.346.151.691.446,1.087.677a2.9,2.9,0,0,0,3.441-.086,3.227,3.227,0,0,1,2.519-.8c.842.108.785.367,1.073-.821,1.058-.187,2.008-.389,1.951-.972a4.395,4.395,0,0,1-2.995-2.2c-.288-.468-.655-1.152.108-1.476.2-.086.468-.13.777-.245a1.107,1.107,0,0,0,.576-.389.48.48,0,0,0-.425-.677c-.619-.043-.914.36-1.289.238a.793.793,0,0,1-.266-.166h.007Z"
      fill="#fff"
      fillRule="evenodd"
      opacity="0.15"
    />
  </svg>
);
