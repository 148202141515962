//@ts-nocheck
import React, { useContext } from 'react'
import Drawer from '@material-ui/core/Drawer'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { GeneralConsumer } from '../../store/GeneralContext'
import MainNavigation from '../MainNavigation'
import { useStyles } from './Styles'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import useStore from "../../store/userContext/context";

// import { NamedRoutes } from '../../routes'
// import { FormattedMessage } from 'react-intl'
// import { NavLink } from 'react-router-dom'
// import { BootConsumer } from '../../BootContext'
import { useTranslation } from "react-i18next";
import useRegionsList from "../../services/GeneralHooks/useRegionsHook";

const MobileMenu = () => {
  const classes = useStyles()
  const { t } = useTranslation();
  const { regions, handleChangeRegions } = useRegionsList();
  const { userState } = useContext(useStore);

  return (
    <GeneralConsumer>{({ mobileMenuState, toggleMobileMenuDrawer }: any) => (
      <>
        <IconButton
          edge="end"
          className={classes.menuButton}
          aria-label="open drawer"
          onClick={() => toggleMobileMenuDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer className={classes.navigationDrawerWrapper} anchor='right' open={mobileMenuState}
          onClose={() => toggleMobileMenuDrawer(false)}>
          <div className={classes.navigationDrawer}>
            <IconButton
              edge="end"
              className={classes.menuCloseButton}
              color="inherit"
              aria-label="close drawer"
              onClick={() => toggleMobileMenuDrawer(true)}
            >
              <CloseIcon />
            </IconButton>
            <MainNavigation />

            <div style={{display:'flex',flexDirection:'column'}}>
              <FormControl
                variant="standard"
                style={{ display: "contents" }}
              // className={formClasses.FormItemSelect}
              >
                <span>{t("header.location")}</span>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  // @ts-ignore
                  value={userState.selectedRegion.id || ""}
                  onChange={handleChangeRegions}
                  label="region"
                >
                  {/* <MenuItem value="">
                                <em>None</em>
                              </MenuItem> */}
                  {regions.map((item: any) => {
                    return (
                      <MenuItem key={item?.id} value={item.id}>
                        {item?.location?.city}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {/* <BootConsumer>
              {({ bootServiceData }) => (
                <MainNavigation pageSettings={bootServiceData?.page_settings}/>
              )}
            </BootConsumer> */}
            {/* <NavLink to={NamedRoutes.requestRegistration} className={classes.introFormLink}
                     onClick={() => toggleMobileMenuDrawer(false)}>
             <p>intro form link</p>
            </NavLink> */}
          </div>
        </Drawer>
      </>
    )}
    </GeneralConsumer>
  )
}

export default MobileMenu
