//@ts-nocheck
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: any) => ({
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    
    transition: 'all 0.5s ease-in-out',
    '.shrink &': {},
    [theme.breakpoints.up('lg')]: {
      // paddingRight: theme.spacing(8),
      '&.inner': {
        paddingRight: theme.spacing(0)
      }
    },
    '& a': {
      display: 'flex',
      flex: '0 0 100'
    }
  },

  LogoImage: {
    '& img': {
      width: 150,
      height: 80,
      transition: 'all 0.5s ease-in-out',
    },

    objectFit: 'contain',
    '.shrink &': {
      '& img': {
        transition: 'all 0.5s ease-in-out',
        width: 70,
        height: 70,
      },
    }
  },
  
}))
