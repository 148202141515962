import { useContext } from "react";
// import message from 'antd';
import { get, post,deleteReq,put } from "../utils/request";
import { useHistory } from "react-router-dom";
import { addToStorage, getFromStorage } from "../utils/localStorage";
// import { getNotifications, readAllNotifications } from './api';
// import userContext from '../store/context';
import userContext from "../store/userContext/context";
import Configuration from "./Configuration";
// import { log } from "../utils/helpers";
import { toast } from "react-toastify";
import { NamedRoutes } from "../routes";
import { GeneralContext } from "../store/GeneralContext";

const useApi = () => {
  const { push } = useHistory();
  const userData = getFromStorage("@fixxPressUser");
  //@ts-ignore
  const { seTabRoute } = useContext(GeneralContext);

  const {
    userState: {
      // accessToken: contextToken,
      refreshToken,
      lang,
      selectedRegion,
    },
    setUserContext,
  } = useContext(userContext);
  let token =
  userData.accessToken !== "" ?  userData.accessToken : "";

  const postRequest = async (
    url: string,
    body?: any,
    showMessage?: boolean,
    region?:any ,
    successMessage =""  
  ) => {
    let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
    let regionSend=!!region?region:userData.selectedRegion?.id
    return await post(url, body, token, langSend,regionSend)
      .then((res) => {
        if(successMessage!==""){
          toast.success(successMessage, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return res.data;
      })
      .catch(async (err) => {
        if (
          err?.response?.data?.status?.code ===
          Configuration.statusCode.refreshToken && err.response?.data?.status?.message!=='Invalid Auth Credentials.'   //add arabic message
        ) {
          try {
            const refreshResponse = await post(
              Configuration.endPoints.refreshToken,
              {
                refresh_token: refreshToken,
              },
              token,
              langSend
            );

            if (refreshResponse?.status === Configuration.statusCode.success) {
              setUserContext((old) => {
                return {
                  ...old,
                  accessToken: refreshResponse?.data.access_token,
                  refreshToken: refreshResponse?.data.refresh_token,
                };
              });
              let user = getFromStorage("@fixxPressUser");
              user = {
                ...user,
                accessToken: refreshResponse.data.access_token,
                refreshToken: refreshResponse.data.refresh_token,
              };
              addToStorage("@fixxPressUser", user);
              const fetchSameApi = await post(url, body,  refreshResponse.data.access_token, langSend);
              if (fetchSameApi.status === Configuration.statusCode.success)
                return fetchSameApi.data;
            }
          } catch (errors) {
            const user = getFromStorage("@fixxPressUser");
            delete user?.refreshToken;
            delete user?.accessToken;
            delete user?.me;
            delete user?.id;
            delete user?.cartDetails;
            addToStorage("@fixxPressUser", user);
            seTabRoute(NamedRoutes.Landing)
            push(NamedRoutes.Landing);
          }
        } else if (showMessage) {
          let message=err?.response?.data?.status?.errors!==''?err?.response?.data?.status?.errors: err?.response?.data?.status?.message
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return false;
      });
  };
  const getRequest = async (
    url: string,
    showMessage?: boolean,
    tokenCache?: string
  ) => {
    let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
    let selectedRegionSend = selectedRegion ? selectedRegion.id : 1;
    let tokenHere = tokenCache ? tokenCache : token;
    return await get(url, tokenHere, langSend, selectedRegionSend)
      .then((res) => {
        return res.data;
      })
      .catch(async (err) => {
        if (
          err?.response?.data?.status?.code ===
          Configuration.statusCode.refreshToken
        ) {
          try {
            const refreshResponse = await post(
              Configuration.endPoints.refreshToken,
              {
                refresh_token: refreshToken,
              },
              token,
              langSend
            );

            if (refreshResponse.status === Configuration.statusCode.success) {
              setUserContext((old) => {
                return {
                  ...old,
                  accessToken: refreshResponse.data.access_token,
                  refreshToken: refreshResponse.data.refresh_token,
                };
              });
              let user = getFromStorage("@fixxPressUser");
              user = {
                ...user,
                accessToken: refreshResponse.data.access_token,
                refreshToken: refreshResponse.data.refresh_token,
              };
              addToStorage("@fixxPressUser", user);
              const fetchSameApi = await get(url,refreshResponse.data.access_token, langSend, selectedRegionSend)
              if (fetchSameApi.status === Configuration.statusCode.success)
                return fetchSameApi.data;
            }
          } catch (errors) {
            let user = getFromStorage("@fixxPressUser");
            delete user?.refreshToken;
            delete user.accessToken;
            delete user?.me;
            delete user?.id;
            delete user?.cartDetails;
            addToStorage("@fixxPressUser", user);
            seTabRoute(NamedRoutes.Landing)
            push(NamedRoutes.Landing);
          }
        }
       else if (showMessage) {
        let message=err?.response?.data?.status?.errors!==''?err?.response?.data?.status?.errors: err?.response?.data?.status?.message
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return false;
      });
  };

  const delRequest = async (
    url: string,
    showMessage?: boolean,
    tokenCache?: string
  ) => {
    let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
    let selectedRegionSend = selectedRegion ? selectedRegion.id : 1;
    let tokenHere = tokenCache ? tokenCache : token;
    return await deleteReq(url, tokenHere, langSend, selectedRegionSend)
      .then((res) => {
        return res.data;
      })
      .catch(async (err) => {
        if (
          err?.response?.data?.status?.code ===
          Configuration.statusCode.refreshToken
        ) {
          try {
            const refreshResponse = await post(
              Configuration.endPoints.refreshToken,
              {
                refresh_token: refreshToken,
              },
              token,
              langSend
            );

            if (refreshResponse.status === Configuration.statusCode.success) {
              setUserContext((old) => {
                return {
                  ...old,
                  accessToken: refreshResponse.data.access_token,
                  refreshToken: refreshResponse.data.refresh_token,
                };
              });
              let user = getFromStorage("@fixxPressUser");
              user = {
                ...user,
                accessToken: refreshResponse.data.access_token,
                refreshToken: refreshResponse.data.refresh_token,
              };
              addToStorage("@fixxPressUser", user);
              const fetchSameApi = await deleteReq(url,  refreshResponse.data.access_token, langSend, selectedRegionSend)
              if (fetchSameApi.status === Configuration.statusCode.success)
                return fetchSameApi.data;
            }
          } catch (errors) {
            const user = getFromStorage("@fixxPressUser");
            delete user?.refreshToken;
            delete user.accessToken;
            delete user?.me;
            delete user?.id;
            delete user?.cartDetails;
            addToStorage("@fixxPressUser", user);
            seTabRoute(NamedRoutes.Landing)
            push(NamedRoutes.Landing);
          }
        }
       else if (showMessage) {
        let message=err?.response?.data?.status?.errors!==''?err?.response?.data?.status?.errors: err?.response?.data?.status?.message
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });
        }
        return false;
      });
  };

  const putRequest = async (
    url: string,
    body?: any,
    showMessage?: boolean,
    region?:number
  ) => {
    let langSend = lang === null ? "en" : lang === "en" ? "en" : "ar";
    let regionSend=!!region?region:userData.selectedRegion?.id
    return await put(url, body, token, langSend,regionSend)
      .then((res) => {
        return res.data;
      })
      .catch(async (err) => {
        if (
          err?.response?.data?.status?.code ===
          Configuration.statusCode.refreshToken && err.response?.data?.status?.message!=='Invalid Auth Credentials.'   //add arabic message
        ) {
          try {
            const refreshResponse = await post(
              Configuration.endPoints.refreshToken,
              {
                refresh_token: refreshToken,
              },
              token,
              langSend
            );

            if (refreshResponse?.status === Configuration.statusCode.success) {
              setUserContext((old) => {
                return {
                  ...old,
                  accessToken: refreshResponse?.data.access_token,
                  refreshToken: refreshResponse?.data.refresh_token,
                };
              });
              let user = getFromStorage("@fixxPressUser");
              user = {
                ...user,
                accessToken: refreshResponse.data.access_token,
                refreshToken: refreshResponse.data.refresh_token,
              };
              addToStorage("@fixxPressUser", user);
              const fetchSameApi = await put(url, body,  refreshResponse.data.access_token, langSend);
              if (fetchSameApi.status === Configuration.statusCode.success)
                return fetchSameApi.data;
            }
          } catch (errors) {
            const user = getFromStorage("@fixxPressUser");
            delete user?.refreshToken;
            delete user?.accessToken;
            delete user?.me;
            delete user?.id;
            delete user?.cartDetails;
            addToStorage("@fixxPressUser", user);
            seTabRoute(NamedRoutes.Landing)
            push(NamedRoutes.Landing);
          }
        } else if (showMessage) {
          let message=err?.response?.data?.status?.errors!==''?err?.response?.data?.status?.errors: err?.response?.data?.status?.message
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme: "light",
          });

        }
        return false;
      });
  };


  return {
    getRequest,
    postRequest,
    delRequest,
    putRequest
  };
};

export default useApi;
