//@ts-nocheck
import React from "react";
import "./App.css";
import { ThemeProvider } from "./store/ThemeProvider";
import { GeneralProvider } from "./store/GeneralContext";
import { BrowserRouter as Router } from "react-router-dom";
import ComingSoon from "./pages/ComingSoon";
import Configuration from "./services/Configuration";
import { HelmetProvider } from "react-helmet-async";
import UserStore from "./store/userContext";
import AppRoutes from "./routes/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <>
      {Configuration.MAINTENANCE === "true" ? (
        <ComingSoon />
      ) : (
        <Router forceRefresh={false}>
          <HelmetProvider>
          <GoogleOAuthProvider clientId={Configuration.google0Auth}>
            <UserStore>
              <GeneralProvider>
                <ThemeProvider>
                  <ToastContainer
                    className={"toasts-container"}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <AppRoutes />
                </ThemeProvider>
              </GeneralProvider>
            </UserStore>
            </GoogleOAuthProvider>
          </HelmetProvider>
        </Router>
      )}
    </>
  );
}
export default App;
