/* eslint-disable react/jsx-pascal-case */
import React, { FC } from "react";
import "./style.css";
import {
  FlxLogo,
  INS_Logo,
  FB_Logo,
  TWITTER_Logo,
  TIKTOK_Logo,
} from "./SVG/icons";
//@ts-ignore
import VideoBg from "./assets/fixxpressVideoc.mp4";
const CartPage: FC = (): JSX.Element => {
  React.useEffect(() => {
    document.title = "Fixxpress | Coming Soon";
  }, []);

  return (
    <div className={"main"}>
      <div className="overLay" />
      <div className="overLay2" />
      <video src={VideoBg}  autoPlay muted loop playsInline className="video">
        {/* <source src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4" type="video/mp4" /> */}
      </video>
      <div className="content">
        <div className="logoContainer">
          <FlxLogo />
        </div>
        <div className="titlesContainer">
          <div className="landingContent">
            <span className="header"> Our New Service Will Be</span>
            <div className="launchingTxtHolder">
              <h1 className="launchingTxt">LAUNCHING SOON</h1>
            </div>
            <h3 className={"description"}>
              We’re working to launch Fixxpress as soon as possible, so stay
              tuned for all service features needed for your car.
            </h3>
          </div>
          <div className="footer">
            <div className="socialIconsHolder">
              <FB_Logo />
              <div className="space10" />
              <INS_Logo />
              <div className="space10" />
              <TWITTER_Logo />
              <div className="space10" />
              <TIKTOK_Logo />
            </div>
            <p>©2022, All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
