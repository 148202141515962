//@ts-nocheck
import React from 'react'
import { NavLink } from 'react-router-dom'
import { GeneralConsumer } from '../../store/GeneralContext'
import { ThemeConsumer } from '../../store/ThemeProvider'
import { NamedRoutes } from '../../routes/index'
import { useStyles } from './Styles'
// import { fontResizerClass } from '../../utils/helpers'
import { useTranslation} from 'react-i18next';
// import { useLocation } from 'react-router-dom'

const MainNavigation = ({ pageSettings }:any) => {
  const {t}= useTranslation()
  let defaultMenu = [
    {
      key: 'Landing',
      label: t('MainNavigation.link.item1'),
      link: NamedRoutes.Landing
    },
    {
      key: 'Services',
      label: t('MainNavigation.link.item2'),
      link: NamedRoutes.services
    },
    {
      key: 'AboutUs',
      label: t('MainNavigation.link.item3'),
      link: NamedRoutes.AboutUs
    },
    {
      key: 'ContactUs',
      label: t('MainNavigation.link.item4'),
      link: NamedRoutes.ContactUs
    }
  ]

  const classes = useStyles()
  // const location = useLocation();
  return (
    <GeneralConsumer>{({ toggleMobileMenuDrawer,selectedTab }:any) => (
      <ThemeConsumer>{({ fontSize }:any) => (

        <div className={classes.mainNavigationWrapper}>
          <nav className={classes.mainNavigation}>
            <ul>
              {defaultMenu.map((item, index) => (
                <li style={{color:selectedTab===item.link?'#F06642':'#565656'}} key={item.key || index}>
                  <NavLink
                    exact
                    activeClassName="active"
                    to={item.link}
                    onClick={() => toggleMobileMenuDrawer(false,item.link)}
                  >
                    <span style={{color:selectedTab===item.link?'#F06642':'#565656'}}>{item.label}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
      </ThemeConsumer>
    )}
    </GeneralConsumer>
  )
}

export default MainNavigation
