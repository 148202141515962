//  It checks if the user is authenticated, if they are,
//  it renders the "component" prop. If not, it redirects
//  the user to /login.
 //@ts-nocheck
import React from 'react'
import {Route} from 'react-router-dom'
import Configuration from '../services/Configuration'
// import {NamedRoutes} from '../routes/index'
import { useHistory } from 'react-router-dom';

const AnonymousRoute = ({component: Component, ...rest}:any) => {
  const history = useHistory();
  return (
    <Route {...rest} render={(props) => (
      Configuration.isAuth() ? <Component {...props} /> :
        history.goBack()
    )}/>
  )
}


export default AnonymousRoute