import { createContext } from "react";

import { State, StoreApi } from "./index.interface";
import { getFromStorage } from "../../utils/localStorage";

export const initialState: State = {
  name: "",
  accessToken: getFromStorage("@fixxPressUser")?.accessToken ||"",
  refreshToken: getFromStorage("@fixxPressUser")?.refreshToken ||"",
  email: "",
  fullName: "",
  selectedRegion: getFromStorage("@fixxPressUser")?.selectedRegion? getFromStorage("@fixxPressUser").selectedRegion: {
    id: 1,
    name: "Abu Dhabi",
    currency: "AED",
    location: {
      city: "Abu Dhabi",
      cityAlt: null,
      country: "United Arab Emirates",
      countryCode: "AE",
      lat: 24.35242237932637,
      long: 54.49183066401384,
      postal_code: "9981",
    },
  }, 
  phoneNumber: "",
  lastPage: "",
  id: "",
  me: {},
  lang: localStorage.getItem("i18nextLng"),
  cartDetails:getFromStorage("@fixxPressUser")?.cartDetails || [],
  selectedCar:getFromStorage("@fixxPressUser")?.selectedCar || {},
  lastSelectedCategory:getFromStorage("@fixxPressUser")?.lastSelectedCategory || '',
};

export default createContext<StoreApi>({
  userState: initialState,
  // @ts-ignore
  setUserContext: (state: State) => state,
});
