/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import YouTubeIcon from "@material-ui/icons/YouTube";
// import TwitterIcon from "@material-ui/icons/Twitter";
import { useStyles } from "./Styles";
// import instagram from "../../../../assets/svg/Footer/instagram.svg";
// import facebook from "../../../../assets/svg/Footer/instagram.svg";

const SocialMedia = () => {
  const classes = useStyles();

  return (
    <div
      className={classes.socialMediaWrapper}
      role="group"
      aria-label="Social Media Wrapper"
    >
      <a
        href="https://www.instagram.com/fixxpress.ae/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="instagram-round-icon"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0ZM9.193,5.413h5.615a3.818,3.818,0,0,1,3.83,3.779v5.6a3.813,3.813,0,0,1-3.83,3.779H9.193a3.813,3.813,0,0,1-3.83-3.779v-5.6A3.817,3.817,0,0,1,9.193,5.413Zm2.771,3.031A3.714,3.714,0,1,1,8.25,12.158,3.714,3.714,0,0,1,11.964,8.444Zm0,1.253a2.455,2.455,0,1,1-2.455,2.455A2.458,2.458,0,0,1,11.964,9.7Zm3.592-1.771a.6.6,0,1,1-.6.6A.6.6,0,0,1,15.556,7.926Zm-5.9-1.44h4.693a3.2,3.2,0,0,1,3.2,3.189V14.4a3.2,3.2,0,0,1-3.2,3.189H9.653A3.2,3.2,0,0,1,6.45,14.4V9.675A3.2,3.2,0,0,1,9.653,6.486Z"
            fill="#e8e9eb"
            fillRule="evenodd"
            opacity="0.702"
          />
        </svg>{" "}
      </a>
      <a
        href="https://www.facebook.com/fixxpress.ae"
        target="_blank"
        rel="noopener noreferrer"
        // style={{ marginLeft: 20 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="facebook"
            d="M24,12.073A12,12,0,1,0,10.125,24V15.563H7.078v-3.49h3.047V9.414c0-3.026,1.792-4.7,4.532-4.7a18.164,18.164,0,0,1,2.686.238V7.925H15.831a1.74,1.74,0,0,0-1.956,1.886v2.265H17.2l-.532,3.49h-2.8V24A12.054,12.054,0,0,0,24,12.073Z"
            fill="#e8e9eb"
            opacity="0.702"
          />
        </svg>
      </a>

      <a
        href="https://www.snapchat.com/add/fixxpress"
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginLeft: 0 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            id="snapchat-round-icon"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm3.83,10.74c.619-3.088-.583-5.665-3.837-5.694-2.325-.014-4.442,1.5-3.866,5.478.072.5-.166.439-.54.353-.31-.065-.626-.137-.936-.2a.649.649,0,0,0-.346.166c-.446.475.454.842.792.972.446.166.936.324.871.849a2.492,2.492,0,0,1-.173.619,4.344,4.344,0,0,1-2.613,2.282c-.554.158-.835.223.007.684a4.3,4.3,0,0,0,1.476.41c.086,1.634.705.583,2.044.857a2.522,2.522,0,0,1,.54.144c.346.151.691.446,1.087.677a2.9,2.9,0,0,0,3.441-.086,3.227,3.227,0,0,1,2.519-.8c.842.108.785.367,1.073-.821,1.058-.187,2.008-.389,1.951-.972a4.395,4.395,0,0,1-2.995-2.2c-.288-.468-.655-1.152.108-1.476.2-.086.468-.13.777-.245a1.107,1.107,0,0,0,.576-.389.48.48,0,0,0-.425-.677c-.619-.043-.914.36-1.289.238a.793.793,0,0,1-.266-.166h.007Z"
            fill="#e8e9eb"
            fillRule="evenodd"
            opacity="0.702"
          />
        </svg>
      </a>
    </div>
  );
};
export default SocialMedia;
