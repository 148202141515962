import { useCallback, useContext } from "react";
  // import i18n from "../../locales/I18n";
import { addToStorage, getFromStorage } from "../../utils/localStorage";
import userStore from "../../store/userContext/context";

const useLanguageHook = () => {
  const {
    setUserContext,
  } = useContext(userStore);

  const handleRadioOnChange = useCallback((event: any) => {
    if (event.target.checked) {
      // console.log(event.target.id);
    }
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLang = useCallback(async () => {
    let user = getFromStorage("@fixxPressUser");
    user = {
      ...user,
      lang: user.lang === "ar" ? "en" : "ar",
    };
    addToStorage("@fixxPressUser", user);
    setUserContext((old) => {
      return {
        ...old,
        lang: old.lang === "ar" ? "en" : "ar",
      };
    });
    localStorage.setItem("i18nextLng", user.lang === "ar" ? "en" : "ar",);
    window.location.reload();
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLangRadioOnChange = useCallback((event: any) => {
    event.preventDefault();
    // console.log(event);
    if (event.target.checked) {
      // console.log(event.target.id);
      handleChangeLang();
    }
         // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    handleRadioOnChange,
    handleLangRadioOnChange,
  };
};

export default useLanguageHook;
